import styled from 'styled-components'
import { grayscale, orange } from '../../../../../../styles/colors'

export const Step = styled.div`
  position: relative;
  min-height: 1em;
  color: gray;

  .cardContent {
    background-color: ${grayscale[100]}
  }

  .caption {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #161616;
    background-color: ${grayscale[100]};
    right: 30px;
    position: relative;
    border-radius: 8px 8px 8px 0;
    padding: 25px 45px;
  }
`

export const Circle = styled.div`
  background: ${orange.extra};
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 12px;
  color: #fff;
  text-align: center;
  font-family: "Sora", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:2;
  top: 35%;
`

export const StepContainer = styled.div`

  ${Step} + ${Step} {
    margin-top: 48px;
  }

  ${Step} > div:first-child {
    position: static;
    height: 0;
  }

  ${Step} > div:not(:first-child) {
    margin-left: 45px;
    padding-left: 14px;
  }

  ${Circle}:after {
    content: ' ';
    position: absolute;
    display: block;
    top: -70px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100px;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 90% -100%;
    background-color: ${orange.extra};
    z-index: -1;
  }

  ${Step}:last-child ${Circle}:after {
    display: none;
  }
`
